import React, { useEffect, useRef } from "react";
import { homepage_services } from "../data";

const Services = () => {
  const { services } = homepage_services;
  const serviceRefs = useRef([]); // References for each service

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // Add the "headin" class when in view
        entry.target.classList.add("headin");
      } else {
        // Remove the "headin" class when out of view
        entry.target.classList.remove("headin");
      }
    });
  };

  useEffect(() => {
    serviceRefs.current = serviceRefs.current.slice(0, services.length); // Ensure refs match services count
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "-75px", // Adjust this to control when the effect triggers
      threshold: 0.5, // Adjust for partial visibility threshold
    });

    serviceRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref); // Observe each element
    });

    return () => {
      serviceRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref); // Clean up observer
      });
    };
  }, [services]);

  return (
    <section className="homepage_services">
      <div className="container">
        <div className="row justify-content-center">
          {services.map((service, index) => (
            <div
              key={service.id}
              ref={(el) => (serviceRefs.current[index] = el)} // Assign ref
              className="service-item" // Default class
            >
              <h3 className="my-3 service-heading text-uppercase text-center">
                {service.heading}
              </h3>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
