import React from 'react'
import { homepage_howitworks } from "../data";

const HowItWorks = () => {

    const { heading, workings } = homepage_howitworks;
  return (
    <>
      <section className="homepage_howitworks">
        <div className="container">
            <div className="row mb-md-3 mb-4">
                <h2 className="gradient section-title text-center">{heading}</h2>
            </div>
            <div className="workings row d-flex">
                    {workings.map((working) => {
                        return(
                            <div className="col-md-6 col-sm-12 col-12 p-2">
                                <div className="work rounded">
                                    <div className="row d-flex work-content">
                                        <div className="number-div col-6 position-relative"><span className="number position-absolute">{working.id}</span></div>
                                        <div className="content col-6 p-4">
                                            {/* <h3 className="work-title">{working.title}</h3> */}
                                            <p className="description">{working.description}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
      </section>
    </>
  )
}

export default HowItWorks
