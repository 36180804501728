import MainBannerImg from "./assets/images/Banner.png";

import WhoWeAreImg from "./assets/images/Who-We-Are.jpg";

// Why Sneakpeak Media BG Video
import video1 from "./assets/images/3.mp4";
import video2 from "./assets/images/5.mp4";
import video3 from "./assets/images/1.mp4";
import video4 from "./assets/images/2.mp4";
import video5 from "./assets/images/4.mp4";

// Client Logos
import MaitriLogo from "./assets/images/MaitriLogo.png";
import RaajkanyaLogo from "./assets/images/RaajkanyaLogo.png";
import LogoutLogo from "./assets/images/LogoutLogo.png";
import WarmWeaveLogo from "./assets/images/WarmWeaveLogo.png";
import ThakurjiLogo from "./assets/images/ThakurjiLogo.jpg";
import KamlaRugsLogo from "./assets/images/KamlaRugsLogo.png";
import LifestyleLogo from "./assets/images/LifestyleLogo.png";

export const homepage_banners = {
  title: "Digitize Ideas",
  description: "Welcome to Sneakpeak Media, The House of Creativity and Innovation. Our expert folks are dedicated to turning your digital dream into reality. Our mission is to empower businesses to gain a competitive edge online. The only way to achieve this is by emphasizing data analytics and performance metrics, which can help you reach potential customers, promote your brand, and increase sales. So we invite you to partner with us and explore our services and how we can help you succeed in your niche and get you exceptional results.",
  mainBannerImg: MainBannerImg,
  button1Text: "Let's Build Together",
  button1Link: "#",
  button2Text: "And Market Together",
  button2Link: "#",
  cta_button_text: "Let's Build Together",
  cta_button_link: "#",
};

export const homepage_whoweare = {
  heading: "Welcome to<br>Sneak Peak Media!",
  description:
    "At Sneak Peak Media, we specialize in empowering businesses to thrive in the digital world. Our comprehensive suite of services includes Social Media Marketing (SMM), Search Engine Optimization (SEO), Web Development, and Social Media Ads. With tailored strategies and a results-driven approach, we help businesses maximize their online presence and achieve their marketing goals.<br><br>Our experienced team brings a wealth of knowledge and expertise to every project, ensuring that our solutions are customized to meet your unique needs. From crafting engaging social media campaigns to optimizing your website for search engines, we're dedicated to delivering measurable results that drive growth and success for your business.<br><br>Ready to elevate your digital presence? Let Sneak Peak Media be your partner in digital success. Contact us today to learn more about how we can help your business thrive online.",
  buttonText: "Know More",
  buttonLink: "/about",
  image: WhoWeAreImg,
};

export const homepage_whyus = {
  heading: "Why Sneakpeak Media",
  why: [
    {
      id: "1",
      video: video1,
      title: "Expertise You Can Trust",
      description:
        "With years of experience in web development, SEO, and social media management, we deliver proven strategies that drive measurable results.",
    },
    {
      id: "2",
      video: video2,
      title: "Tailored Solutions for You",
      description:
        "We believe every business is unique. Our solutions are customized to meet your specific goals, ensuring maximum impact and growth.",
    },
    {
      id: "3",
      video: video3,
      title: "Data-Driven Approach",
      description:
        "Our strategic decisions are guided by deep insights and advanced analytics, ensuring every effort is meticulously optimized for peak performance.",
    },
    {
      id: "4",
      video: video4,
      title: "End-to-End Support",
      description:
        "From strategy to execution and beyond, we provide full-spectrum support, keeping you informed and empowered every step of the way.",
    },
    {
      id: "5",
      video: video5,
      title: "Results That Matter",
      description:
        "Our focus is on delivering tangible outcomes—better rankings, increased traffic, higher engagement, and a stronger online presence.",
    },
  ],
};

export const hompeage_moto = {
  motos: [
    {
      id: 1,
      heading: "Optimize",
    },
    {
      id: 2,
      heading: "Engage",
    },
    {
      id: 3,
      heading: "Convert",
    },
    {
      id: 4,
      heading: "Retain",
    },
  ],
};

export const homepage_services = {
  title: "Our Services",
  content:
    "At Sneak Peak Media, we specialize in empowering businesses to thrive in the digital world. Our comprehensive suite of services includes Social Media Marketing (SMM), Search Engine Optimization (SEO), Web Development, and Social Media Ads.<br><br>With tailored strategies and a results-driven approach, we help businesses maximize their online presence and achieve their marketing goals. Our experienced team brings a wealth of knowledge and expertise to every project, ensuring that our solutions are customized to meet your unique needs.<br><br>From crafting engaging social media campaigns to optimizing your website for search engines, we're dedicated to delivering measurable results that drive growth and success for your business.<br><br>Ready to elevate your digital presence? Let Sneak Peak Media be your partner in digital success. Contact us today to learn more about how we can help your business thrive online",
  buttonText: "Know More",
  buttonLink: "/services",
  services: [
    {
      id: 1,
      icon: "fa-solid fa-code mt-5 service-icons",
      heading: "Website Development",
      description:
        "Elevate your online presence with our innovative, creative, and functional web development expertise.",
    },
    {
      id: 2,
      icon: "fa-solid fa-icons mt-5 service-icons",
      heading: "Social Media Marketing",
      description:
        "Spark engagement, cultivate community, and amplify your brand's digital footprint with our bespoke social media mastery.",
    },
    {
      id: 3,
      icon: "fa-solid fa-maximize mt-5 service-icons",
      heading: "Search Engine Optimization",
      description:
        "Our SEO wizards work magic, boosting your website's visibility and driving targeted traffic to new heights.",
    },
    {
      id: 4,
      icon: "fas fa-ad mt-5 service-icons",
      heading: "Social Media Advertisement",
      description:
        "Targeted ads on platforms like Facebook, Instagram, and LinkedIn to expand brand reach and drive conversions effectively.",
    },
    {
      id: 5,
      icon: "fas fa-ad mt-5 service-icons",
      heading: "Wsebsite Maintenance",
      description:
        "Targeted ads on platforms like Facebook, Instagram, and LinkedIn to expand brand reach and drive conversions effectively.",
    },
    {
      id: 6,
      icon: "fas fa-ad mt-5 service-icons",
      heading: "Content Writing",
      description:
        "Targeted ads on platforms like Facebook, Instagram, and LinkedIn to expand brand reach and drive conversions effectively.",
    },
  ],
};

export const homepage_blogs = {
  heading: "Blogs",
  content:
    "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Inventore necessitatibus, molestias corrupti ",
  blogs: [
    {
      id: 1,
      title: "Hello World 1",
      description:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Inventore necessitatibus, molestias corrupti",
      blogUrl: "https://google.com/",
      image:
        "https://images.pexels.com/photos/1566909/pexels-photo-1566909.jpeg?cs=srgb&dl=pexels-alexander-grey-1566909.jpg&fm=jpg",
    },
    {
      id: 2,
      title: "Hello World 2",
      description:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Inventore necessitatibus, molestias corrupti",
      blogUrl: "https://youtube.com/",
      image:
        "https://media.istockphoto.com/id/1372528688/vector/colorful-horizontal-retro-background-in-style-hippie.jpg?s=612x612&w=0&k=20&c=hIogg58mVhNQL4o-uIUY4e1q_B8vuOsIrpKuFCD9CCw=",
    },
    {
      id: 3,
      title: "Hello World 3",
      description:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Inventore necessitatibus, molestias corrupti",
      blogUrl: "https://facebook.com/",
      image:
        "https://img.freepik.com/free-vector/psychedelic-optical-illusion-background-concept_23-2148556808.jpg?size=626&ext=jpg&ga=GA1.1.1224184972.1711756800&semt=sph",
    },
  ],
};

export const homepage_howitworks = {
  heading: "How It Works",
  workings: [
    {
      id: "01",
      title: "Understand & Strategize",
      description:
        "We analyze your goals and craft a custom plan for web development, SEO, and social media success.",
    },
    {
      id: "02",
      title: "Design & Build",
      description:
        "We create stunning, responsive websites and optimize them for visibility and user engagement.",
    },
    {
      id: "03",
      title: "Execute & Optimize",
      description:
        "We implement innovative strategies and continuously refine for better digital marketing presence.",
    },
    {
      id: "04",
      title: "Measure & Report",
      description:
        "Get clear insights with detailed reports showing progress, results, and actionable next steps.",
    },
  ],
};

export const homepage_clientlogos = {
  logos: [
    {
      id: 1,
      clientName: "Maitri Holidays",
      logoImg: MaitriLogo,
    },
    {
      id: 2,
      clientName: "Raajkanya",
      logoImg: RaajkanyaLogo,
    },
    {
      id: 3,
      clientName: "Logout Holidays",
      logoImg: LogoutLogo,
    },
    {
      id: 4,
      clientName: "Warm Weave",
      logoImg: WarmWeaveLogo,
    },
    // {
    //   id: 5,
    //   clientName: "Thakurji Foods",
    //   logoImg: ThakurjiLogo,
    // },
    {
      id: 6,
      clientName: "Kamla Rugs",
      logoImg: KamlaRugsLogo,
    },
    // {
    //   id: 7,
    //   clientName: "Lifestyle Care",
    //   logoImg: LifestyleLogo,
    // },
  ]
}
