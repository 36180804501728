import React from "react";
import { homepage_whyus } from "../data.js";

const AboutUs = () => {
  const { heading, why } = homepage_whyus;

  return (
    <section className="homepage_about">
      <div className="container">
        <div className="row mb-md-3 mb-4">
          <h2 className="gradient section-title text-center">{heading}</h2>
        </div>
        <div className="boxes gap-4">
          <div className="box-container row">
            {why.map((item, index) => (
              <div
                className={`${
                  index < 2 ? "col-md-6" : "col-md-4"
                } p-2`}
                key={item.id}
              >
                <div className="box">
                  <video loop autoPlay muted playsInline>
                    <source src={item.video} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <div className="box-content p-3">
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
