import React from "react";
import { hompeage_moto } from "../data";

const Moto = () => {
  const { motos } = hompeage_moto;

  return (
    <>
      <section className="homepage_moto">
        <div className="container">
          <div className="row">
            {motos.map((moto, index) => (
              <div
                key={index}
                className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-6 mb-3"
              >
                <div className="moto d-flex align-items-center justify-content-center">
                  <h3 className="moto-heading">{moto.heading}</h3>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Moto;
