import React from "react";
import "./assets/css/home.css";
import Services from "./home/Services.jsx";
import Banner from "./home/Banner.jsx";
import Moto from "./home/Moto.jsx";
// import Blogs from "./home/Blogs.jsx";
import Tagline from "./home/Tagline.jsx";
// import WhoWeAre from "./home/WhoWeAre.jsx";
import HowItWorks from "./home/HowItWorks.jsx";
import AboutUs from "./home/AboutUs.jsx";
import ClientLogos from "./home/ClientLogos.jsx";

const Home = () => {
  return (
    <div>
        <Banner/>
        <Tagline />
        <AboutUs/>
        {/* <WhoWeAre /> */}
        <Moto/>
        <Services/>
        <ClientLogos/>
        {/* <Blogs /> */}
        <HowItWorks/>
    </div>
  );
};

export default Home;
