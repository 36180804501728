import React from 'react';
import { homepage_clientlogos } from '../data';

const ClientLogos = () => {
  const { logos } = homepage_clientlogos;

  return (
    <>
      <section id="homepage_logos">
        <div className="client-logos container">
          <div className="logos">
            {logos.concat(logos).map((logo, index) => (
              <img
                key={index}
                className=""
                src={logo.logoImg}
                alt={logo.clientName}
              />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default ClientLogos;
